import { Checkbox, TreeView, TreeViewCheckboxElement, TreeViewCheckboxProps } from '@farol-ds/react'
import { forwardRef } from 'react'

import { ROOT_NODE_ID } from '../constants'
import { calculateTreeviewIndeterminatesInDepth2 } from '../fns/calculate-treeview-indeterminates-in-depth-2'
import { useTreeViewFilterContext } from '../use-treeview-filter-context'

type Element = TreeViewCheckboxElement
interface Props extends Omit<TreeViewCheckboxProps, 'children'> {
  defaultSelected: string[]
  expandedItems?: string[]
  rootLabel?: string
}

export const TreeViewFilterCheckboxListCollapsed = forwardRef<Element, Props>(
  function TreeViewFilterCheckboxListCollapsed(props, forwardedRef) {
    const { options } = useTreeViewFilterContext()
    const { defaultSelected, rootLabel = 'Todos', expandedItems, ...rest } = props

    function shouldExpand(nodeId: string) {
      return expandedItems?.includes(nodeId) || undefined
    }

    return (
      <TreeView.Checkbox
        {...rest}
        ref={forwardedRef}
        defaultExpanded={[ROOT_NODE_ID]}
        defaultIndeterminate={calculateTreeviewIndeterminatesInDepth2(
          defaultSelected,
          ROOT_NODE_ID
        )}
        defaultSelected={defaultSelected}
      >
        <TreeView.Item
          label={
            <Checkbox.Item>
              <Checkbox id={ROOT_NODE_ID} />
              <Checkbox.Content>
                <Checkbox.Name>{rootLabel}</Checkbox.Name>
              </Checkbox.Content>
            </Checkbox.Item>
          }
          nodeId={ROOT_NODE_ID}
        >
          {options.map((option) => (
            <TreeView.Item
              expanded={shouldExpand(option.nodeId)}
              label={
                <Checkbox.Item>
                  <Checkbox id={option.nodeId} />
                  <Checkbox.Content>
                    <Checkbox.Name>{option.label}</Checkbox.Name>
                  </Checkbox.Content>
                </Checkbox.Item>
              }
              nodeId={option.nodeId}
              key={option.value}
            >
              {option.children?.map((child) => (
                <TreeView.Item
                  expanded={shouldExpand(child.nodeId)}
                  label={
                    <Checkbox.Item>
                      <Checkbox id={child.nodeId} />
                      <Checkbox.Content>
                        <Checkbox.Name>{child.label}</Checkbox.Name>
                      </Checkbox.Content>
                    </Checkbox.Item>
                  }
                  nodeId={child.nodeId}
                  key={child.value}
                >
                  {child.children?.map((cc) => (
                    <TreeView.Item
                      label={
                        <Checkbox.Item>
                          <Checkbox id={cc.nodeId} />
                          <Checkbox.Content>
                            <Checkbox.Name>{cc.label}</Checkbox.Name>
                          </Checkbox.Content>
                        </Checkbox.Item>
                      }
                      nodeId={cc.nodeId}
                      key={cc.value}
                    />
                  ))}
                </TreeView.Item>
              ))}
            </TreeView.Item>
          ))}
        </TreeView.Item>
      </TreeView.Checkbox>
    )
  }
)

export type TreeViewFilterCheckboxListCollapsedElement = Element
export type TreeViewFilterCheckboxListCollapsedProps = Props
