//
// TODO: refactor to be a shared component. @gabrielrtakeda
// ref: libs/painel-lgpd/core/src/components/filter/*
//
import { FormControl, FormControlElement, FormControlProps } from '@farol-ds/react'
import { assignSubComponents } from '@farol-ds/react/utilities/internal'
import { forwardRef } from 'react'

import { TreeViewFilterContext } from '../context'
import { mapTreeViewFilterNodes } from '../fns'
import { TreeViewFilterCheckboxList } from '../treeview-filter-checkbox-list'
import { TreeViewFilterSearch } from '../treeview-filter-search'
import { Option, OptionRaw } from '../types'
import { useTreeViewFilterProvider } from '../use-treeview-filter-context'

export type TreeViewFilterElement = FormControlElement

export interface TreeViewFilterProps extends FormControlProps {
  options: OptionRaw[] | Option[]
}

const TreeViewFilterRoot = forwardRef<TreeViewFilterElement, TreeViewFilterProps>(
  function TreeViewFilter(props, forwardedRef) {
    const { children, options: _options, ...rest } = props
    const options = mapTreeViewFilterNodes(_options)
    const context = useTreeViewFilterProvider({ options })
    return (
      <TreeViewFilterContext.Provider value={context}>
        <FormControl {...rest} ref={forwardedRef}>
          {children}
        </FormControl>
      </TreeViewFilterContext.Provider>
    )
  }
)

export const TreeViewFilter = assignSubComponents('TreeViewFilter', TreeViewFilterRoot, {
  Search: TreeViewFilterSearch,
  CheckboxList: TreeViewFilterCheckboxList,
})
