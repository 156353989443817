import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'

import { ROOT_VALUE } from '../constants'

type OnSelectedChangeFn = (
  selected: string[],
  callback: (rootOrValues: string[], rootOrCount: number, hasRoot: boolean) => void
) => void

export interface UseTreeViewFilterProps {
  initialSelected?: string[]
  initialSelectedCount?: number
}

export interface UseTreeViewFilterReturn {
  onApply: (callback: (closeFromApply: boolean, nextOpen: boolean) => void) => void
  onOpenChange: (nextOpen: boolean) => void
  onSelectedChange: OnSelectedChangeFn
  open: boolean
  selected: string[]
  selectedCount: number
  setOpen: Dispatch<SetStateAction<boolean>>
  setSelected: Dispatch<SetStateAction<string[]>>
  setSelectedCount: Dispatch<SetStateAction<number>>
}

export function useTreeViewFilter(props?: UseTreeViewFilterProps): UseTreeViewFilterReturn {
  const { initialSelected = [ROOT_VALUE], initialSelectedCount = 0 } = props || {}

  // responsible to handle when to discard changes.
  const closeFromApply = useRef(false)

  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState<string[]>(initialSelected)
  const [selectedCount, setSelectedCount] = useState<number>(initialSelectedCount)

  // discard staged changes.
  useEffect(() => {
    if (!open && !closeFromApply.current) {
      setSelected(initialSelected)
      setSelectedCount(initialSelectedCount)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const onApply: UseTreeViewFilterReturn['onApply'] = (callback) => {
    closeFromApply.current = true
    setOpen(false)
    callback(closeFromApply.current, false)
  }

  const onOpenChange: UseTreeViewFilterReturn['onOpenChange'] = (nextOpen) => {
    closeFromApply.current = false
    setOpen(nextOpen)
  }

  const onSelectedChange: UseTreeViewFilterReturn['onSelectedChange'] = (selected, callback) => {
    const hasRoot = selected.some((v) => v === ROOT_VALUE)
    const rootOrValues = hasRoot ? [ROOT_VALUE] : selected
    const rootOrCount = hasRoot ? 0 : selected.length
    callback(rootOrValues, rootOrCount, hasRoot)
  }

  return {
    onApply,
    onOpenChange,
    onSelectedChange,
    open,
    selected,
    selectedCount,
    setOpen,
    setSelected,
    setSelectedCount,
  }
}
