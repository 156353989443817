import { ROOT_VALUE } from '@jusbrasil-web/shared-treeview-filter'

export enum AgeRangeEnum {
  UP_TO_24 = 'ate_24',
  BETWEEN_25_AND_44 = 'entre_25_e_44',
  BETWEEN_45_AND_64 = 'entre_45_e_64',
  OVER_64 = 'acima_de_64',
}

export const ALL_AGES = 'ALL'

export const AGE_RANGE_OPTIONS = [
  { label: 'Todas as idades', value: ALL_AGES },
  { label: 'Até 24 anos', value: AgeRangeEnum.UP_TO_24 },
  { label: 'Entre 25 e 44 anos', value: AgeRangeEnum.BETWEEN_25_AND_44 },
  { label: 'Entre 45 e 64 anos', value: AgeRangeEnum.BETWEEN_45_AND_64 },
  { label: '65 anos ou mais', value: AgeRangeEnum.OVER_64 },
]

export const AGE_RANGE_MAPPER: { [key in AgeRangeEnum]: { begin?: number; end?: number } } = {
  [AgeRangeEnum.UP_TO_24]: {
    begin: 24,
  },
  [AgeRangeEnum.BETWEEN_25_AND_44]: {
    begin: 44,
    end: 25,
  },
  [AgeRangeEnum.BETWEEN_45_AND_64]: {
    begin: 64,
    end: 45,
  },
  [AgeRangeEnum.OVER_64]: {
    end: 65,
  },
}

export const STATE_FILTER_OPTIONS = [
  { nodeId: '1.0', label: 'Acre', value: 'AC' },
  { nodeId: '1.1', label: 'Alagoas', value: 'AL' },
  { nodeId: '1.2', label: 'Amapá', value: 'AP' },
  { nodeId: '1.3', label: 'Amazonas', value: 'AM' },
  { nodeId: '1.4', label: 'Bahia', value: 'BA' },
  { nodeId: '1.5', label: 'Ceará', value: 'CE' },
  { nodeId: '1.6', label: 'Distrito Federal', value: 'DF' },
  { nodeId: '1.7', label: 'Espírito Santo', value: 'ES' },
  { nodeId: '1.8', label: 'Goiás', value: 'GO' },
  { nodeId: '1.9', label: 'Maranhão', value: 'MA' },
  { nodeId: '1.10', label: 'Mato Grosso', value: 'MT' },
  { nodeId: '1.11', label: 'Mato Grosso do Sul', value: 'MS' },
  { nodeId: '1.12', label: 'Minas Gerais', value: 'MG' },
  { nodeId: '1.13', label: 'Pará', value: 'PA' },
  { nodeId: '1.14', label: 'Paraíba', value: 'PB' },
  { nodeId: '1.15', label: 'Paraná', value: 'PR' },
  { nodeId: '1.16', label: 'Pernambuco', value: 'PE' },
  { nodeId: '1.17', label: 'Piauí', value: 'PI' },
  { nodeId: '1.18', label: 'Rio de Janeiro', value: 'RJ' },
  { nodeId: '1.19', label: 'Rio Grande do Norte', value: 'RN' },
  { nodeId: '1.20', label: 'Rio Grande do Sul', value: 'RS' },
  { nodeId: '1.21', label: 'Rondônia', value: 'RO' },
  { nodeId: '1.22', label: 'Roraima', value: 'RR' },
  { nodeId: '1.23', label: 'Santa Catarina', value: 'SC' },
  { nodeId: '1.24', label: 'São Paulo', value: 'SP' },
  { nodeId: '1.25', label: 'Sergipe', value: 'SE' },
  { nodeId: '1.26', label: 'Tocantins', value: 'TO' },
]

export const STATE_FILTER_OPTION_VALUES = [
  ROOT_VALUE,
  ...STATE_FILTER_OPTIONS.map(({ value }) => value),
]
