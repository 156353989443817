import {
  Banner,
  TreeViewCheckboxElement,
  TreeViewCheckboxProps,
  WarningIcon,
} from '@farol-ds/react'
import { forwardRef, useMemo } from 'react'

import { ROOT_VALUE } from '../constants'
import { expandOptions } from '../fns'
import { useTreeViewFilterContext } from '../use-treeview-filter-context'
import { TreeViewFilterCheckboxListCollapsed } from './treeview-filter-checkbox-list-collapsed'
import { TreeViewFilterCheckboxListExpanded } from './treeview-filter-checkbox-list-expanded'

export type Element = TreeViewCheckboxElement
interface Props extends Omit<TreeViewCheckboxProps, 'children' | 'onSelectedChange'> {
  defaultSelected?: TreeViewCheckboxProps['defaultSelected']
  onSelectedChange?: (nodeid: string, selected: string[], selectedNodeIds?: string[]) => void
  rootLabel?: string
  expandedItems?: string[]
}

export const TreeViewFilterCheckboxList = forwardRef<Element, Props>(
  function TreeViewFilterCheckboxList(props, forwardedRef) {
    const {
      options, //
      optionsValues,
      optionsEnum,
      optionsFiltered,
      query,
    } = useTreeViewFilterContext()

    const {
      defaultSelected = [ROOT_VALUE], //
      onSelectedChange,
      rootLabel,
      ...rest
    } = props

    const hasRoot = defaultSelected.some((value) => value === ROOT_VALUE)
    const defaultSelectedValues = hasRoot ? optionsValues : expandOptions(options, defaultSelected)
    const _defaultSelected = useMemo(
      () => defaultSelectedValues.map((v) => optionsEnum.get(v) || ROOT_VALUE),
      [defaultSelectedValues, optionsEnum]
    )

    const _onSelectedChange: Props['onSelectedChange'] = (nodeid, selected) => {
      if (onSelectedChange) {
        const isAll = selected.length === optionsValues.length
        const selectedValues = selected.map((v) => optionsEnum.get(v) || '')
        onSelectedChange(nodeid, isAll ? selectedValues : selectedValues.filter(Boolean), selected)
      }
    }

    return (
      <>
        {optionsFiltered.length === 0 && (
          <Banner defaultVisible type="critical">
            <Banner.Content>
              <Banner.Title>
                <Banner.Icon>
                  <WarningIcon />
                </Banner.Icon>
                Nenhum resultado encontrado
              </Banner.Title>
            </Banner.Content>
          </Banner>
        )}

        {optionsFiltered.length > 0 && !query.length && (
          <TreeViewFilterCheckboxListCollapsed
            {...rest}
            ref={forwardedRef}
            defaultSelected={_defaultSelected}
            onSelectedChange={_onSelectedChange}
            rootLabel={rootLabel}
          />
        )}
        {/* [NOTE]: @gabrielrtakeda
         * this is an workaround until we cannot control the above <TreeView />
         * component expanded state programmaticaly.
         */}
        {optionsFiltered.length > 0 && !!query.length && (
          <TreeViewFilterCheckboxListExpanded
            {...rest}
            ref={forwardedRef}
            defaultSelected={_defaultSelected}
            onSelectedChange={_onSelectedChange}
            rootLabel={rootLabel}
          />
        )}
      </>
    )
  }
)

export type TreeViewFilterCheckboxListElement = Element
export type TreeViewFilterCheckboxListProps = Props
