import { Dispatch, SetStateAction, useContext, useMemo, useState } from 'react'

import { ROOT_NODE_ID, ROOT_VALUE } from './constants'
import { TreeViewFilterContext } from './context'
import { normalizeLabel } from './fns'
import { Option } from './types'

interface ProviderProps {
  options: Option[]
}

interface ProviderReturn {
  options: Option[]
  optionsValues: string[]
  optionsEnum: Map<string, string>
  optionsFiltered: Option[]
  query: string
  setQuery: Dispatch<SetStateAction<string>>
}

export function useTreeViewFilterProvider(props: ProviderProps): ProviderReturn {
  const { options } = props
  const [query, setQuery] = useState('')

  const optionsValues = useMemo(() => {
    const courtsValues = [ROOT_VALUE]
    for (const court of options) {
      courtsValues.push(court.value)
      for (const child of court.children || []) {
        courtsValues.push(child.value)
        for (const cc of child.children || []) {
          courtsValues.push(cc.value)
        }
      }
    }
    return courtsValues
  }, [options])

  const optionsEnum = useMemo(() => {
    // TODO: [multi-depth] expected: ^1.0.0.0.0...$ · current: ^1.0.0$ @gabrielrtakeda
    const _enum = new Map<string, string>()
      // default `all` items wrapper initial value support.
      .set(ROOT_VALUE, ROOT_NODE_ID)
      .set(ROOT_NODE_ID, ROOT_VALUE)

    options.forEach((option, optionIndex) => {
      _enum.set(option.value, `${ROOT_NODE_ID}.${optionIndex}`)
      _enum.set(`${ROOT_NODE_ID}.${optionIndex}`, option.value)

      option.children?.forEach((child, childIndex) => {
        _enum.set(child.value, `${ROOT_NODE_ID}.${optionIndex}.${childIndex}`)
        _enum.set(`${ROOT_NODE_ID}.${optionIndex}.${childIndex}`, child.value)

        child.children?.forEach((cc, ccIndex) => {
          _enum.set(cc.value, `${ROOT_NODE_ID}.${optionIndex}.${childIndex}.${ccIndex}`)
          _enum.set(`${ROOT_NODE_ID}.${optionIndex}.${childIndex}.${ccIndex}`, cc.value)
        })
      })
    })
    return _enum
  }, [options])

  const optionsFiltered = useMemo(() => {
    if (!query) return options

    const normalizedQuery = normalizeLabel(query)
    return options
      .map((option: Option) => {
        const filteredChildren: Option[] = []
        for (const child of option.children || []) {
          const filteredGrandchildren =
            child.children?.filter((cc) => normalizeLabel(cc.label).includes(normalizedQuery)) || []
          if (normalizeLabel(child.label).includes(normalizedQuery)) {
            filteredChildren.push({
              ...child,
              children: filteredGrandchildren || [],
            })
          } else if (filteredGrandchildren.length > 0) {
            filteredChildren.push({ ...child, children: filteredGrandchildren })
          }
        }
        return { ...option, children: filteredChildren }
      })
      .filter(
        ({ label, children }: Option) =>
          (children || []).length > 0 || normalizeLabel(label).includes(normalizedQuery)
      )
  }, [options, query])

  return {
    options,
    optionsValues,
    optionsEnum,
    optionsFiltered,
    query,
    setQuery,
  }
}

export function useTreeViewFilterContext() {
  return useContext(TreeViewFilterContext)
}

export type UseTreeViewFilterProviderReturn = ProviderReturn
