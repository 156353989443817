import { CheckboxFilterModal } from '@jusbrasil-web/lawsuit/shared'
import { AgeRangeEnum } from '@jusbrasil-web/shared-apollo-client/types'
import { ROOT_VALUE } from '@jusbrasil-web/shared-treeview-filter'
import { useRouter } from 'next/router'
import { useState } from 'react'

import { ensureArrayString } from '../../utils/array'
import { FilterByAgeRange } from './filter-by-age-range/filter-by-age-range'
import { STATE_FILTER_OPTIONS } from './options'
import styles from './person-filters-names.module.scss'

export function PersonFilters() {
  const router = useRouter()
  const initialAgeRange = router.query.faixaEtaria as AgeRangeEnum
  const initialStates = ensureArrayString(router.query.uf)

  const [selectedStates, setSelectedStates] = useState<string[]>(initialStates || [ROOT_VALUE])
  const [selectedAgeRange, setSelectedAgeRange] = useState<AgeRangeEnum | null>(initialAgeRange)

  const onStatesFilter = (values: string[]) => {
    setSelectedStates(values)

    router.push(
      {
        query: {
          ...router.query,
          uf: values,
        },
      },
      undefined,
      { scroll: false }
    )
  }

  const onAgeRangeChange = (ageRange: AgeRangeEnum | null) => {
    setSelectedAgeRange(ageRange)

    router.push(
      {
        query: {
          ...router.query,
          faixaEtaria: ageRange ? ageRange : [],
        },
      },
      undefined,
      { scroll: false }
    )
  }

  return (
    <div className={styles.container}>
      <CheckboxFilterModal
        label="Estado"
        modalTitle="Filtrar por Estado"
        searchPlaceholder="Ex.: Bahia"
        selectedItems={selectedStates}
        options={STATE_FILTER_OPTIONS}
        onFilter={onStatesFilter}
      />
      <FilterByAgeRange selectedAgeRange={selectedAgeRange} onFilter={onAgeRangeChange} />
    </div>
  )
}
