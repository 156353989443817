import { createContext } from 'react'

import type { UseTreeViewFilterProviderReturn } from './use-treeview-filter-context'

export type TreeViewFilterContext = UseTreeViewFilterProviderReturn

export const TreeViewFilterContext = createContext<TreeViewFilterContext>({
  options: [],
  optionsValues: [],
  optionsEnum: new Map(),
  optionsFiltered: [],
  query: '',
  setQuery: () => undefined,
})
