const uniq = (arg: string[]) => arg.filter((val, idx, all) => all.indexOf(val) === idx)

//                                                        depth: 0 1 2 ...
// TODO: [refactor] add support for more than the depth 2 — i.e. 0.0.0 — @gabrielrtakeda
export const calculateTreeviewIndeterminatesInDepth2 = (values: string[], rootNodeId: string) => {
  if (values.includes(rootNodeId) || !values.length) return []

  const groups = values.filter((v) => /^\d+\.\d$/.test(v))
  const childs = values.filter((v) => /^\d+\.\d+\.\d$/.test(v))
  const orphans = childs.filter((z) => !groups.some((g) => z.startsWith(g)))
  const indeterminates = uniq(orphans.map((o) => o.match(/^(\d+\.\d+)\.\d+$/)?.[1] || ''))
  return [rootNodeId, ...indeterminates]
}
