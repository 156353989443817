import { gql, useQuery } from '@apollo/client'
import { BodyText, Button, Container, Heading, Separator, Spinner } from '@farol-ds/react'
import { InputMaybe, StateEnum } from '@jusbrasil-web/shared-apollo-client/types'
import { nullableConnectionNodes } from '@jusbrasil-web/shared-apollo-client/utilities'
import { LoginTrigger } from '@jusbrasil-web/shared-bifrost'
import { getSanitizedQuery } from '@jusbrasil-web/shared-utils-url'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'

import { APP_ID } from '../../utils'
import { useContextData } from '../context-provider'
import { PersonEntityCard } from '../person-entity-card'
import { PersonFilters } from '../person-filters-names'
import { AgeRangeEnum } from '../person-filters-names/options'
import { EntitiesListQuery } from './__generated__/entities-list.graphql'
import styles from './entities-list.module.scss'

export const ENTITIES_LIST_FRAGMENT = gql`
  fragment EntitiesList_entities on ContactLawsuitPartyEntitiesConnection {
    pageInfo {
      hasNextPage
      endCursor
    }
    total
    edges {
      node {
        ...PersonEntityCard_lawsuitPartyEntity
      }
    }
  }
  ${PersonEntityCard.fragments.lawsuitPartyEntity}
`

export const ENTITIES_LIST_CONTACT_FRAGMENT = gql`
  fragment EntitiesList_contact on Contact {
    contactId
    name
    totalCanonicalEntities
    entitiesListParties: lawsuitPartyEntities(
      first: $entitiesListLimit
      after: $after
      filters: $filters
      source: "haystack"
    ) @connection(key: "EntitiesList") {
      ...EntitiesList_entities
    }
  }
  ${ENTITIES_LIST_FRAGMENT}
`

export const ENTITIES_LIST_QUERY = gql`
  query EntitiesList(
    $contactId: String!
    $entitiesListLimit: Int!
    $after: String
    $filters: LawsuitPartyEntitiesFilterInput
    $maskSeniorAge: Boolean
  ) {
    root {
      contact(contactId: $contactId) {
        ...EntitiesList_contact
      }
    }
  }
  ${ENTITIES_LIST_CONTACT_FRAGMENT}
`

interface EntitiesListProps {
  contact: NonNullable<EntitiesListQuery['root']['contact']>
  displayedLawsuitCount: number
}

export function EntitiesList({ contact, displayedLawsuitCount }: EntitiesListProps) {
  const { dispatchEvent, isLogged } = useContextData()

  const totalCanonicalEntities = contact?.totalCanonicalEntities || 0
  const lawsuitPartyEntities = contact?.entitiesListParties
  const totalEntities = lawsuitPartyEntities?.total || 0
  const [peopleFoundCount, setPeopleFoundCount] = useState(totalEntities)
  const [fetchingMoreEntities, setFetchingMoreEntities] = useState(false)
  const [hasBeenFiltered, setHasBeenFiltered] = useState(false)
  const [entities, setEntities] = useState(() => nullableConnectionNodes(lawsuitPartyEntities))
  const router = useRouter()

  const filters = useMemo(() => {
    const filterQueryStates = (
      router.query?.uf?.constructor === String ? [router.query.uf] : router.query.uf
    ) as InputMaybe<StateEnum[]>

    const filterQueryAge = Object.entries(AgeRangeEnum).find(
      ([_, value]) => value === router.query?.faixaEtaria
    )?.[0] as keyof typeof AgeRangeEnum

    return {
      ...(filterQueryStates?.length ? { lawsuitStates: filterQueryStates } : {}),
      ...(filterQueryAge ? { ageRange: filterQueryAge } : {}),
    }
  }, [router.query])

  const variables = useMemo(
    () => ({
      contactId: contact?.contactId as NonNullable<string>,
      entitiesListLimit: 10,
      after: null,
      filters,
      maskSeniorAge: false,
    }),
    [contact?.contactId, filters]
  )

  const { data, error, loading, fetchMore } = useQuery<EntitiesListQuery>(ENTITIES_LIST_QUERY, {
    variables,
    fetchPolicy: 'network-only',
  })

  const connection = data?.root?.contact?.entitiesListParties
  const { pageInfo } = connection || {}
  const { hasNextPage } = pageInfo || {}

  useEffect(() => {
    if (!loading) {
      setEntities(nullableConnectionNodes(connection))
      setPeopleFoundCount(connection?.total || 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connection])

  useEffect(() => {
    if (filters && Object.keys(filters).length > 0) {
      setHasBeenFiltered(true)
      const query = contact?.name || ''
      const sanitized_query = getSanitizedQuery({ query })

      dispatchEvent('Topic.Filtered', {
        from_component: 'EntitiesList',
        artifact: 'PESSOA',
        metadata: {
          lawsuitCount: displayedLawsuitCount,
          personCount: totalCanonicalEntities,
          personCountGeneral: totalEntities,
          query: query,
          sanitized_query: sanitized_query,
          filtered_by: filters,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, loading])

  async function fetchMoreEntities() {
    if (!loading) {
      setFetchingMoreEntities(true)
      try {
        await fetchMore({
          variables: {
            ...variables,
            after: pageInfo?.endCursor,
          },
        })
      } finally {
        setFetchingMoreEntities(false)
      }
    }
  }

  function onClick(): void {
    if (loading) return

    dispatchEvent('Topic.ViewMoreClicked', {
      from_component: 'ViewMoreButton',
      artifact: 'PESSOA',
      metadata: {
        lawsuitCount: displayedLawsuitCount,
        personCount: totalCanonicalEntities,
        personCountGeneral: totalEntities,
      },
    })

    if (isLogged) {
      fetchMoreEntities()
    }
  }

  const loginOptions = {
    eventData: {
      app_id: APP_ID,
      feature_code: 'unlock_entities_list',
      from_component: 'EntitiesListButton',
    },
  }

  if (error) {
    console.error('Error loading entities:', error)
    return null
  }

  return (
    <>
      <Container className={styles.list} data-testid="entities-list">
        <Heading size="md" data-testid="entities-list-title" asChild>
          <h2>{peopleFoundCount} pessoas identificadas</h2>
        </Heading>
        <BodyText size="md" data-testid="entities-list-description">
          Selecione a pessoa e verifique se existem processos pelo CPF dela.
        </BodyText>
        <PersonFilters />
        {loading && hasBeenFiltered ? (
          <div className={styles.spinner} data-test-id="spinner-entities-list">
            <Spinner size="lg" label="Carregando" />
          </div>
        ) : (
          entities?.map((entity, index) => (
            <PersonEntityCard
              key={`card-${entity.entityId}`}
              partyEntity={entity}
              fromComponent="EntitiesList"
              personPosition={index + 1}
              totalEntitiesRelated={totalEntities}
              totalCanonicalEntities={totalCanonicalEntities}
            />
          ))
        )}
        <LoginTrigger options={loginOptions} isLogged={isLogged}>
          {!loading && hasNextPage ? (
            <Button
              className={styles.list__button}
              data-testid="entities-list-button"
              kind="secondary"
              size="md"
              onClick={onClick}
              loading={fetchingMoreEntities}
            >
              Mostrar mais pessoas
            </Button>
          ) : (
            <Separator className={styles.divider} data-test-id="separator-entities-list" />
          )}
        </LoginTrigger>
      </Container>
    </>
  )
}

EntitiesList.fragments = {
  contact: ENTITIES_LIST_CONTACT_FRAGMENT,
}
