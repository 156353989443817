import { Option } from '../types'
import { omitParent } from './omit-parent'

const noop = <K>(_: Option[], arg: K) => arg
const uniq = <K>(arg: K[]) => arg.filter((val, idx, all) => all.indexOf(val) === idx)

type ExpandOptionsConfig = { onlyChilds: boolean }

export const expandOptions = (
  options: Option[],
  values: string[] = [],
  config?: ExpandOptionsConfig
) => {
  const { onlyChilds = false } = config || {}
  return (onlyChilds ? omitParent : noop<typeof values>)(
    options,
    uniq<string>(
      values.reduce((prev: string[], value: string) => {
        const parent = options.find((option) => option.value === value)
        const childs = (
          parent?.children?.map((child) => [
            child.value,
            ...(child.children || []).map((cc) => cc.value),
          ]) || []
        ).flat()
        return [...prev, value, ...childs]
      }, [])
    )
  )
}
