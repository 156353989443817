import { Search, SearchElement, SearchProps } from '@farol-ds/react'
import classNames from 'classnames'
import { forwardRef } from 'react'

import { useTreeViewFilterContext } from '../use-treeview-filter-context'
import styles from './treeview-filter-search.module.scss'

export type TreeViewFilterSearchElement = SearchElement
export interface TreeViewFilterSearchProps extends SearchProps {
  onValueChangeAfter?: (event: React.ChangeEvent<SearchElement>, value: string) => void
  onValueChangeBefore?: (event: React.ChangeEvent<SearchElement>, value: string) => void
}

export const TreeViewFilterSearch = forwardRef<
  TreeViewFilterSearchElement,
  TreeViewFilterSearchProps
>(function TreeViewFilterSearch(props, forwardedRef) {
  const { query, setQuery } = useTreeViewFilterContext()
  const { onValueChangeAfter, onValueChangeBefore, ...rest } = props

  const onValueChange: SearchProps['onValueChange'] = (event, value) => {
    onValueChangeBefore?.(event, value)
    setQuery(value)
    onValueChangeAfter?.(event, value)
  }

  return (
    <Search
      {...rest}
      ref={forwardedRef}
      containerClassName={classNames(rest.containerClassName, styles.inputContainer)}
      defaultValue={query}
      onValueChange={onValueChange}
      size="md"
      type="secondary"
    />
  )
})
