import { Checkbox, TreeView, TreeViewCheckboxElement, TreeViewCheckboxProps } from '@farol-ds/react'
import classNames from 'classnames'
import { forwardRef, useMemo } from 'react'

import { ROOT_NODE_ID } from '../constants'
import { calculateTreeviewIndeterminatesInDepth2 } from '../fns/calculate-treeview-indeterminates-in-depth-2'
import { Option } from '../types'
import { useTreeViewFilterContext } from '../use-treeview-filter-context'
import styles from './treeview-filter-checkbox-list-expanded.module.scss'

const hasSomeParent = (options: Option[], option: Option) =>
  options.some(({ nodeId }) => nodeId === option.nodeId)

const hasSomeChild = (options: Option[], child: Option): boolean =>
  options.some(
    ({ children }) =>
      !!children?.some(({ nodeId }) => nodeId === child.nodeId || hasSomeChild(children, child))
  )

type Element = TreeViewCheckboxElement
interface Props extends Omit<TreeViewCheckboxProps, 'children'> {
  defaultSelected: string[]
  rootLabel?: string
}

// [NOTE]
// this component is an workaround until we cannot control <TreeView /> expanded
// state programmatically. so, we force rerender <TreeViewFilterCheckboxList /> as expanded.
// ref: https://jusbrasil.slack.com/archives/C06FQCA6RS9/p1720624912011509?thread_ts=1720571868.051869&cid=C06FQCA6RS9
//
// TODO: refactor this. @gabrielrtakeda @andersonba
export const TreeViewFilterCheckboxListExpanded = forwardRef<Element, Props>(
  function TreeViewFilterCheckboxListExpanded(props, forwardedRef) {
    const { options, optionsValues, optionsEnum, optionsFiltered } = useTreeViewFilterContext()
    const { defaultSelected, rootLabel = 'Todos', ...rest } = props

    const defaultExpanded = useMemo(
      () => [ROOT_NODE_ID, ...optionsValues.map((v) => optionsEnum.get(v) || '')],
      [optionsEnum, optionsValues]
    )
    const defaultIndeterminate = useMemo(
      () => calculateTreeviewIndeterminatesInDepth2(defaultSelected, ROOT_NODE_ID),
      [defaultSelected]
    )

    const renderTree = (options: Option[]) =>
      options.map((option) => {
        const isHidden = !hasSomeParent(optionsFiltered, option)
        return (
          <TreeView.Item
            className={classNames({ [styles.hidden]: isHidden })}
            label={
              <Checkbox.Item>
                <Checkbox id={option.nodeId} />
                <Checkbox.Content>
                  <Checkbox.Name>{option.label}</Checkbox.Name>
                </Checkbox.Content>
              </Checkbox.Item>
            }
            nodeId={option.nodeId}
            key={option.value}
          >
            {renderChildren(option, isHidden)}
          </TreeView.Item>
        )
      })

    const renderChildren = (option: Option, isParentHidden: boolean) =>
      option.children?.map((child) => {
        const isHidden = isParentHidden || !hasSomeChild(optionsFiltered, child)
        return (
          <TreeView.Item
            className={classNames({ [styles.hidden]: isHidden })}
            label={
              <Checkbox.Item>
                <Checkbox id={child.nodeId} />
                <Checkbox.Content>
                  <Checkbox.Name>{child.label}</Checkbox.Name>
                </Checkbox.Content>
              </Checkbox.Item>
            }
            nodeId={child.nodeId}
            key={child.value}
          >
            {(child.children || []).length > 0 && renderChildren(child, isHidden)}
          </TreeView.Item>
        )
      })

    return (
      <TreeView.Checkbox
        {...rest}
        ref={forwardedRef}
        defaultExpanded={defaultExpanded}
        defaultIndeterminate={defaultIndeterminate}
        defaultSelected={defaultSelected}
      >
        <TreeView.Item
          label={
            <Checkbox.Item>
              <Checkbox id={ROOT_NODE_ID} />
              <Checkbox.Content>
                <Checkbox.Name>{rootLabel}</Checkbox.Name>
              </Checkbox.Content>
            </Checkbox.Item>
          }
          nodeId={ROOT_NODE_ID}
        >
          {/* TODO: [multi-depth] expected: ^1.0.0.0.0...$ · current: ^1.0.0$ @gabrielrtakeda */}
          {renderTree(options)}
        </TreeView.Item>
      </TreeView.Checkbox>
    )
  }
)

export type TreeViewFilterCheckboxListExpandedElement = Element
export type TreeViewFilterCheckboxListExpandedProps = Props
