import { ROOT_NODE_ID } from '../constants'
import { Option, OptionRaw } from '../types'

export const mapTreeViewFilterNodes = (rawOpts: OptionRaw[], parentNodeId?: string): Option[] => {
  return rawOpts.map((raw, index) => {
    const nodeId = `${parentNodeId || ROOT_NODE_ID}.${index}`
    const children = raw?.children ? mapTreeViewFilterNodes(raw.children, nodeId) : null
    return { ...raw, nodeId, children }
  })
}
