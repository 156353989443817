import { ChipClickable, Dropdown, MenuItem } from '@farol-ds/react'
import { AgeRangeEnum } from '@jusbrasil-web/shared-apollo-client/types'
import classNames from 'classnames'

import { AGE_RANGE_OPTIONS, ALL_AGES } from '../options'
import styles from './filter-by-age-range.module.scss'

interface FilterByAgeRangeProps {
  selectedAgeRange: AgeRangeEnum | null
  onFilter: (ageRange: AgeRangeEnum | null) => void
}

export function FilterByAgeRange(props: FilterByAgeRangeProps) {
  const { selectedAgeRange, onFilter } = props

  const ageRangeLabel =
    AGE_RANGE_OPTIONS.find(({ value }) => value === selectedAgeRange)?.label || 'Idade'

  const onAgeRangeChange = (value: string) => {
    const selected = value != ALL_AGES ? (value as AgeRangeEnum) : null
    onFilter(selected)
  }

  return (
    <Dropdown>
      <Dropdown.Trigger>
        <ChipClickable
          chevron
          size="lg"
          className={classNames({ [styles.ageRangeSelected]: !!selectedAgeRange })}
          data-testid="age-range-trigger"
        >
          <span className={styles.ageRangeSelected__text}>{ageRangeLabel}</span>
        </ChipClickable>
      </Dropdown.Trigger>
      <Dropdown.Menu title="Selecionar intervalo de idades">
        <Dropdown.MenuRadioGroup onValueChange={onAgeRangeChange}>
          {AGE_RANGE_OPTIONS.filter(
            (option) => !(!selectedAgeRange && option.value == ALL_AGES)
          ).map((option) => (
            <Dropdown.MenuRadioItem key={option.value} value={option.value}>
              <MenuItem>
                <MenuItem.Content>{option.label}</MenuItem.Content>
              </MenuItem>
            </Dropdown.MenuRadioItem>
          ))}
        </Dropdown.MenuRadioGroup>
      </Dropdown.Menu>
    </Dropdown>
  )
}
